import {request} from "./http.service";

export const authRequest = ({
	email,
	password,
}) => request.post({
	path: 'login',
	data: {
		email,
		password
	}
});

export const userRequest = () => request.get({
	path: 'private/users/me'
});
