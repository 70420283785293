
import React, { useEffect, useState, useContext } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AdjustmentsIcon, CheckIcon, CogIcon, HomeIcon, PlusIcon, SearchIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from 'react-bootstrap';

import CreateCompanyModal from "../components/CreateCompanyModal";
import { request } from '../services/http.service';
import { convertDataToView } from '../services/date.service';
import { Toasts } from 'components/Toast';
import {CompaniesTable} from "../components/tables/companies-table";
import {UserContext} from "../context/context.provider";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const { currentUser } = useContext(UserContext);
  const [companies, setCompanies] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [showToast, setShowToast] = useState(false);
  const [toastSettings, setToastSettings] = useState({});
  const selectedCompaniesIds = companies.filter(u => u.isSelected).map(u => u._id);
  const totalCompanies = companies.length;
  const allSelected = selectedCompaniesIds.length === totalCompanies;
  const canCreateNewCompany = !!currentUser?.boUserPermissions?.companyCreate;

  useEffect(() => {
    const response = request.get({
      path: 'private/companies/all',
    });
    response.then(res => {
      const filledData = res.data.map(prepareCompanyData)
      setCompanies(filledData)
    })
  }, []);

  const prepareCompanyData = (company) => ({
    ...company, isSelected: false, show: true, dateCreated: convertDataToView(company.createdAt),
  })

  const [showModal, setShowModal] = useState(false);
  const openCreateCompanyModal = () => setShowModal(true);
  const closeCreateCompanyModal = () => setShowModal(false);

  const changeSearchValue = (e) => {
    const newSearchValue = e.target.value;
    const newCompanies = companies.map(u => ({ ...u, show: u.name.toLowerCase().includes(newSearchValue.toLowerCase()) }));

    setSearchValue(newSearchValue);
    setCompanies(newCompanies);
  };

  const changeStatusFilter = (e) => {
    const newStatusFilter = e.target.value;
    const newCompanies = companies.map(u => ({ ...u, show: u.status === newStatusFilter || newStatusFilter === "all" }));
    setStatusFilter(newStatusFilter);
    setCompanies(newCompanies);
  };

  const selectAllCompanies = () => {
    const newCompanies = selectedCompaniesIds.length === totalCompanies ?
      companies.map(u => ({ ...u, isSelected: false })) :
      companies.map(u => ({ ...u, isSelected: true }));

    setCompanies(newCompanies);
  };

  const selectCompany = (id) => {
    const newCompanies = companies.map(u => u._id === id ? ({ ...u, isSelected: !u.isSelected }) : u);
    setCompanies(newCompanies);
  };

  const deleteCompanies = async () => {
    const ids = selectedCompaniesIds;
    const companiesToBeDeleted = ids ? ids : selectedCompaniesIds;
    const companiesNr = companiesToBeDeleted.length;
    const textMessage = companiesNr === 1
      ? "Are you sure do you want to delete this company?"
      : `Are you sure do you want to delete these ${companiesNr} companies?`;

    const result = await SwalWithBootstrapButtons.fire({
      icon: "error",
      title: "Confirm deletion",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      companiesToBeDeleted.forEach(deleteRequest)
      const newCompanies = companies.filter(f => !companiesToBeDeleted.includes(f._id));
      const confirmMessage = companiesNr === 1 ? "The company has been deleted." : "The companies have been deleted.";

      setCompanies(newCompanies);
      await SwalWithBootstrapButtons.fire('Deleted', confirmMessage, 'success');
    }
  };

  const deleteRequest = async (id) => {
    await request.delete({
      path: `private/companies/${id}`,
    });
  }

  const handleAddCompany = (newCompany) => {
    createCompany(newCompany).then((companyData) => {
      closeCreateCompanyModal();
      setCompanies((companies) => [...companies, prepareCompanyData(companyData.data)]);

      setToastSettings({
        message: 'Company created susscessfuly',
        background: 'success'
      });

      setShowToast(true);
    })
  }

  const createCompany = (data) => {
    return request.post({
      path: `private/companies/create`,
      data
    });
  };

  const handleCloseToast  = () => {
    setShowToast(false);
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item active>Companies List</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Companies List</h4>
          <p className="mb-0">Companies management</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="gray-800" size="sm" className="d-inline-flex align-items-center" onClick={openCreateCompanyModal} disabled={!canCreateNewCompany}>
            <PlusIcon className="icon icon-xs me-2" /> New Company
          </Button>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={8} className="d-md-flex">
            <InputGroup className="me-2 me-lg-3 fmxw-300">
              <InputGroup.Text>
                <SearchIcon className="icon icon-xs" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search companies"
                value={searchValue}
                onChange={changeSearchValue}
              />
            </InputGroup>
            <Form.Select value={statusFilter} className="fmxw-200 d-none d-md-inline" onChange={changeStatusFilter}>
              <option value="all">All</option>
              <option value="enabled">Enabled</option>
              <option value="disabled">Disabled</option>
            </Form.Select>
          </Col>
          <Col xs={3} lg={4} className="d-flex justify-content-end">
            <ButtonGroup>
              <Dropdown className="me-1">
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <AdjustmentsIcon className="icon icon-sm" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                  <CogIcon className="icon icon-sm" />
                  <span className="visually-hidden">Toggle Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                  <span className="small ps-3 fw-bold text-dark">
                    Show
                  </span>
                  <Dropdown.Item className="d-flex align-items-center fw-bold">
                    10 <CheckIcon className="icon icon-xxs ms-auto" />
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                  <Dropdown.Item className="fw-bold rounded-bottom">30</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      </div>

      {
        showToast && <Toasts
          settings={toastSettings}
          handleCloseToast={handleCloseToast}
        />
      }

      <CompaniesTable
        companies={companies.filter(u => u.show)}
        allSelected={allSelected}
        selectCompany={selectCompany}
        deleteCompanies={deleteCompanies}
        selectAllCompanies={selectAllCompanies}
      />
      { showModal && <CreateCompanyModal
        handleAddCompany={handleAddCompany}
        closeModal={closeCreateCompanyModal}
      />}
    </>
  );
};
