import React, {useContext} from "react";
import { MenuAlt1Icon, UserCircleIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Button, Navbar, Dropdown, Container } from 'react-bootstrap';

import { useHistory } from "react-router-dom";

import { Routes } from "routes";
import {UserContext} from "../context/context.provider";

export default ({
  toggleContracted,
}) => {
  const history = useHistory();
  const { currentUser } = useContext(UserContext);

  const userPage = () => {
    history.push(Routes.User.path);
  };

  const logingOut = () => {
    history.push(Routes.SignIn.path);
  };

  return currentUser && (
    <Navbar expand variant="dark" className="navbar-top navbar-dashboard ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Button
              size="lg"
              id="sidebar-toggle"
              variant="icon-only"
              className="sidebar-toggle d-none d-lg-inline-block align-items-center justify-content-center me-3"
              onClick={toggleContracted}
            >
              <MenuAlt1Icon className="toggle-icon" />
            </Button>
          </div>
          <Nav className="align-items-center">

            <Dropdown as={Nav.Item} className="px-lg-3">
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <UserCircleIcon className="avatar rounded-circle text-gray-400 me-2" />
                  {/*<UserCircleIcon className="dropdown-icon text-gray-400 me-2" />*/}
                  {/*<Image src={UserCircleIcon} className="avatar rounded-circle" />*/}
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold text-gray-900">{currentUser.name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-2 py-1">
                <Dropdown.Item className="d-flex align-items-center"  onClick={userPage}>
                  <UserCircleIcon className="dropdown-icon text-gray-400 me-2" />
                  My Profile
                </Dropdown.Item>
                <Dropdown.Divider as="div" className="my-1" />

                <Dropdown.Item className="d-flex align-items-center" onClick={logingOut}>
                  <LogoutIcon className="dropdown-icon text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
