import { Routes } from "routes";
import { useHistory } from "react-router-dom";
import React, {useContext, useState} from "react";
import { LockClosedIcon, MailIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, Container, InputGroup } from 'react-bootstrap';

import BgImage from "assets/img/illustrations/signin.svg";

import {authRequest} from "../services/auth.service";
import {UserContext} from "../context/context.provider";

export default () => {
  const { refreshCurrentUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const history = useHistory();

  const goToUserPage = () => {
    refreshCurrentUser();
    history.push(Routes.HomePage.path);
  };

  async function login() {
    if(!formData.email) return;

    try {
      const response = await authRequest({
        email: formData.email,
        password: formData.password
      });

      if(response.status === 201) {
        goToUserPage();
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    login()
  };

  return (
    <main>
      <section className="d-flex align-items-center vh-lg-100 mt-5 mt-lg-0 bg-soft">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <MailIcon className="icon icon-xs text-gray-600" />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        name="email"
                        placeholder="example@company.com"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <LockClosedIcon className="icon icon-xs text-gray-600" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <div className="d-grid">
                    <Button variant="gray-800" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
