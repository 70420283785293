
import React, { useState, useContext } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CSSTransition } from 'react-transition-group';
import {OfficeBuildingIcon, XIcon, CreditCardIcon, CashIcon, UsersIcon} from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Badge, Image, Button, Navbar, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";
import ReactHero from "assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "assets/img/team/profile-picture-3.jpg";
import {UserContext} from "../context/context.provider";

export default (props = {}) => {
  const { currentUser } = useContext(UserContext);
  const location = useLocation();
  const { pathname } = location;
  // const [show, setShow] = useState(false);
  const [show, setShow] = useState(false);
  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";
  const showCompaniesListTab = !!currentUser?.boUserPermissions?.companyView;
  const showWalletsListTab = !!currentUser?.boUserPermissions?.walletView;
  const showTransactionListTab = !!currentUser?.boUserPermissions?.transactionView;
  const showUsersTab = !!currentUser?.boUserPermissions?.boUserView;

  // const onCollapse = () => setShow(!show);
  const onCollapse = () => {};
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const NavItem = (props) => {
    const { title, link, target, icon: NavItemIcon, image, badgeText, badgeBg, badgeColor = "white" } = props;
    const classNames = badgeText ? "d-flex align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted">
                {title[0]}
              </span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-sm notification-count">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar as={Col} xs={12} expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-lg-none">
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${contracted} ${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-lg me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h5 className="mb-3">Hi, Jane</h5>
                  <Button as={Link} variant="secondary" size="sm" to={Routes.SignIn.path} className="d-inline-flex align-items-center">
                    <LogoutIcon className="icon icon-xxs me-1" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Infinito Payments" link={Routes.HomePage.path} image={ReactHero} />
              {
                showCompaniesListTab && <NavItem title="Companies List" icon={OfficeBuildingIcon} link={Routes.Companies.path} />
              }
              {
                showWalletsListTab &&  <NavItem title="Wallets" badgeText="1" badgeBg="danger" icon={CashIcon} link={Routes.Wallets.path} />
              }
              {
                showTransactionListTab &&  <NavItem title="Transactions" icon={CreditCardIcon} link={Routes.Transactions.path} />
              }
              {
                showUsersTab &&  <NavItem title="Users" icon={UsersIcon} link={Routes.Users.path} />
              }
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
