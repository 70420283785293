import {useHistory} from "react-router-dom";
import React, {useState} from "react";
import {Routes} from "../../routes";
import {Button, ButtonGroup, Card, Dropdown, Form, FormCheck, Image, Nav, Pagination, Table} from "react-bootstrap";
import {DotsHorizontalIcon, ShieldExclamationIcon, XCircleIcon} from "@heroicons/react/solid";
import {capitalizeFirstLetter, getFirstLetterOfEachWord} from "../../utils/common";

export const CompaniesTable = (props) => {
	const bulkActions = {
		changeStatus: 'Enable/Disable',
		delete: 'Delete',
	}

	const {
		companies = [],
		allSelected,
		deleteCompanies,
	} = props;
	const history = useHistory();
	const disabledBulkMenu = companies.filter(c => c.isSelected).length === 0;
	const [bulkOption, setBulkOption] = useState(bulkActions.changeStatus);

	// sort for table view
	companies.sort((a, b) => {
		return new Date(b.createdAt) - new Date(a.createdAt)
	});

	const bulkActionChange = (e) => {
		const newOption = e.target.value;
		setBulkOption(newOption);
	}

	const applyBulkAction = () => {
		if (bulkOption === bulkActions.delete)
			deleteCompanies();
	}

	const selectCompany = (id) => {
		props.selectCompany && props.selectCompany(id);
	};

	const selectAllCompanies = () => {
		props.selectAllCompanies && props.selectAllCompanies();
	};

	// const deleteCompanies = (ids) => {
	//   props.deleteCompanies && props.deleteCompanies(ids)
	// }

	const goToCompanyPage = (id) => {
		history.push(`${Routes.Company.path}/${id}`);
	}

	const TableRow = (props) => {
		const { _id, status, image, name, dateCreated, isSelected } = props;
		const enabled = status === "enabled";
		const statusVariant = enabled
			? "success"
			: "danger";
		const onboardAction = enabled
			? "Downboard"
			: "Onboard";

		return (
			<tr className="border-bottom">
				<td>
					<FormCheck type="checkbox" className="dashboard-check">
						<FormCheck.Input id={`company-${_id}`} checked={isSelected} onChange={() => selectCompany(_id)} />
						<FormCheck.Label htmlFor={`company-${_id}`} />
					</FormCheck>
				</td>
				<td>
					<Card.Link to={Routes.Company.path} className="d-flex align-items-center" onClick={() => goToCompanyPage(_id)}>
						{image
							? (
								<Image
									src={image}
									className="avatar rounded-circle me-3"
								/>
							) : (
								<div className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary me-3">
									<span>{getFirstLetterOfEachWord(name)}</span>
								</div>
							)}
						<div className="d-block">
							<span className="fw-bold">{name}</span>
						</div>
					</Card.Link>
				</td>
				<td><span className="fw-normal">{dateCreated}</span></td>
				<td>
          <span className={`fw-normal text-${statusVariant}`}>
            {capitalizeFirstLetter(status)}
          </span>
				</td>
				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<DotsHorizontalIcon className="icon icon-xs" />
						</Dropdown.Toggle>
						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							<Dropdown.Item className="d-flex align-items-center">
								<ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
								{onboardAction}
							</Dropdown.Item>
							<Dropdown.Item className="d-flex align-items-center">
								<XCircleIcon className="dropdown-icon text-danger me-2" />
								Remove
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		);
	};

	return (
		<Card border="0" className="table-wrapper table-responsive shadow">
			<Card.Body>
				<div className="d-flex mb-3">
					<Form.Select className="fmxw-200" value={bulkOption} onChange={bulkActionChange}>
						{Object.entries(bulkActions).map(([key, value]) => (
							<option key={key} value={value}>{value}</option>
						))}
					</Form.Select>
					<Button variant="secondary" size="sm" className="ms-3"
									disabled={disabledBulkMenu}
									onClick={(e) => applyBulkAction(e.target.value)}>
						Apply
					</Button>
				</div>
				<Table hover className="company-table align-items-center">
					<thead>
					<tr>
						<th className="border-bottom">
							<FormCheck type="checkbox" className="dashboard-check">
								<FormCheck.Input id="companyCheckAll" checked={allSelected} onChange={selectAllCompanies} />
								<FormCheck.Label htmlFor="companyCheckAll" />
							</FormCheck>
						</th>
						<th className="border-bottom">Name</th>
						<th className="border-bottom">Date Created</th>
						<th className="border-bottom">Status</th>
						<th className="border-bottom">Action</th>
					</tr>
					</thead>
					<tbody className="border-0">
					{companies.map(u => <TableRow key={`company-${u._id}`} {...u} />)}
					</tbody>
				</Table>
				<Card.Footer className="px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
					<Nav>
						<Pagination className="mb-0">
							<Pagination.Prev>
								Previous
							</Pagination.Prev>
							<Pagination.Item active>1</Pagination.Item>
							<Pagination.Item>2</Pagination.Item>
							<Pagination.Item>3</Pagination.Item>
							<Pagination.Item>4</Pagination.Item>
							<Pagination.Item>5</Pagination.Item>
							<Pagination.Next>
								Next
							</Pagination.Next>
						</Pagination>
					</Nav>
					<small className="fw-normal small mt-4 mt-lg-0">
						Showing <b>{companies.length}</b> out of <b>25</b> entries
					</small>
				</Card.Footer>
			</Card.Body>
		</Card>
	);
};
