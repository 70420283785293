import React from "react";
import {Button} from 'react-bootstrap';
import {Modal,} from 'react-bootstrap';
import { CompanyInfoForm } from "components/CompanyInfo.form";

const CreateCompanyModal = ({handleAddCompany, closeModal}) => {
	const companyDraft = {
		name: '',
		registrationNumber: '',
		email: '',
		phone: '',
		address: {}
	};

	const handleCreateCompany = (event) => {
		handleAddCompany(event);
	};

	// const RequiredLabel = ({label}) => (
	// 	<Form.Label>
	// 		{label}
	// 		<span className="text-danger"> * </span>
	// 	</Form.Label>
	// );

	return (
		<Modal className="create-company-dialog" centered size={'lg'} show={true}>
			<div className="modal-content p-3" >
				<Modal.Header className="pb-0 border-0">
					<h5 className="fw-normal">
						{'Create new company'}
					</h5>
					<Button
						variant="close"
						onClick={closeModal}
					/>
				</Modal.Header>
				<Modal.Body className="pb-0">
					<CompanyInfoForm
						company={companyDraft}
						onSubmit={handleCreateCompany}
					/>
				</Modal.Body>
			</div>
		</Modal>
	)
};

export default CreateCompanyModal;
