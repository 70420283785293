import React, { useState } from 'react';
import { Toast, ToastContainer} from 'react-bootstrap';

export const Toasts = ({ settings, handleCloseToast})=> {
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        handleCloseToast();
    }

    return (
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            <Toast bg={settings.background} onClose={handleClose} show={show} delay={3000} autohide>
                <Toast.Header>
                    <strong className="me-auto">Infinito Payments</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body>{settings.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};