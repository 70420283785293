export const Routes = {
    // pages
    HomePage: { path: "/" },
    SignIn: { path: "/sign-in" },
    Companies: { path: "/companies"},
    Company: { path: "/company"},
    User: { path: "/me"},
    Transactions: { path: "/transactions"},
    Wallets: { path: "/wallets"},
    Users: { path: "/users"},
};