import React, {createContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Routes} from "../routes";
import {userRequest} from "../services/auth.service";

export const UserContext = createContext();

export const ContextProvider = ({children}) => {
	const [currentUser, setCurrentUser] = useState(null);
	const history = useHistory();

	useEffect(() => {
		currentUserRequest()
	}, []);

	const goToLoginPage = () => {
		history.push(Routes.SignIn.path);
	};

	const currentUserRequest = async () => {
		await userRequest({path: 'private/users/me'})
			.then(res => {
				setCurrentUser(res?.data)
			})
			.catch(error => {
				if (error.response && error.response.status === 401) {
					goToLoginPage();
				} else {
					console.error(error);
				}
			})
	}

	const refreshCurrentUser = async () => {
		await currentUserRequest();
	}

	return (
		<UserContext.Provider value={{currentUser, refreshCurrentUser}}>
			{children}
		</UserContext.Provider>
	);
};
