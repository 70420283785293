import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// core styles
import "./scss/volt.scss";

// vendor styles
import "leaflet/dist/leaflet.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datetime/css/react-datetime.css";


import ScrollToTop from "./components/ScrollToTop";
import AppComponent from "./pages/AppComponent";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop />
    <AppComponent />
  </BrowserRouter>
  , document.getElementById("root")
);
