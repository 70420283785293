import React, {useState, useContext} from 'react';
import {Route, Switch} from "react-router-dom";
import {Routes} from "routes";

// pages
import User from "pages/User";
import SignIn from "pages/SignIn";
import Company from "pages/Company";
import Companies from "pages/Companies";
import Transactions from "pages/Transactions";
// components
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import {ContextProvider, UserContext} from "../context/context.provider";

const RouteWithSidebar = ({component: Component, ...rest}) => {
	const { currentUser } = useContext(UserContext);

	const resize = () => {
		let resize = setInterval(() => {
			window.dispatchEvent(new Event('resize'));
		}, 10);
		setTimeout(function () {
			clearInterval(resize);
		}, 301);
	}

	const localStorageIsContracted = () => {
		return localStorage.getItem('sidebarContracted') !== 'false'
	}

	const [contracted, setContracted] = useState(localStorageIsContracted());
	// const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
	const [contractSidebar, setContractSidebar] = useState(false);

	const toggleMouseOver = () => {
		if (contracted) {
			// setContractSidebar(!contractSidebar);
		}
		resize();
	};

	const toggleContracted = () => {
		setContracted(!contracted);
		setContractSidebar(!contracted);
		localStorage.setItem('sidebarContracted', !contracted);
		resize();
	};

	return (
		<Route {...rest} render={props => (
			<>
				<Sidebar
					contracted={contractSidebar}
					onMouseEnter={toggleMouseOver}
					onMouseLeave={toggleMouseOver}
				/>

				<main className="content">
					{currentUser && <Topbar
						toggleContracted={toggleContracted}
					/>}
					<Component {...props} />
				</main>
			</>
		)}
		/>
	);
};

const PageMock = () => {
	return (
		<>
			{'Available Soon!'}
		</>
	)
}

export default () => (
	<ContextProvider>
		<Switch>
			<Route exact path={Routes.SignIn.path} component={SignIn}/>
			<RouteWithSidebar exact path={Routes.HomePage.path} component={Companies}/>
			<RouteWithSidebar exact path={Routes.Companies.path} component={Companies}/>
			<RouteWithSidebar exact path={`${Routes.Company.path}/:id/`} component={Company}/>
			<RouteWithSidebar exact path={`${Routes.Company.path}/:id/wallets`} component={Company}/>
			<RouteWithSidebar exact path={`${Routes.User.path}`} component={User}/>
			<RouteWithSidebar exact path={`${Routes.Wallets.path}`} component={PageMock}/>
			<RouteWithSidebar exact path={`${Routes.Transactions.path}`} component={Transactions}/>
			<RouteWithSidebar exact path={Routes.Users.path} component={PageMock}/>
		</Switch>
	</ContextProvider>
);
