import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";

export const CompanyInfoForm = ({company, onSubmit, isDisabled}) => {
	const defaultValue = '';

	const [name, setName] = useState(company.name);
	const [registrationNumber, setRegistrationNumber] = useState(company.registrationNumber || defaultValue);
	const [email, setEmail] = useState(company.email || defaultValue);
	const [phone, setPhone] = useState(company.phone || defaultValue);
	// address
	const [street, setStreet] = useState(company.address?.street || defaultValue);
	const [streetNumber, setStreetNumber] = useState(company.address?.streetNumber || defaultValue);
	const [city, setCity] = useState(company.address?.city || defaultValue);
	const [country, setCountry] = useState(company.address?.country || '0');
	const [zipCode, setZipCode] = useState(company.address?.zipCode || defaultValue);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const updatedData = {
			name,
			registrationNumber,
			email,
			phone,
			address: {
				street,
				streetNumber,
				country,
				zipCode,
				city
			}
		};

		onSubmit(updatedData);
	};

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">General information</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="name">
								<Form.Label>Company name</Form.Label>
								<Form.Control required type="text" placeholder="Empty" disabled={isDisabled}
															value={name} onChange={e => setName(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="regNumber">
								<Form.Label>Registration number</Form.Label>
								<Form.Control type="text" placeholder="Empty" disabled={isDisabled}
															value={registrationNumber} onChange={e => setRegistrationNumber(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="email">
								<Form.Label>Email</Form.Label>
								<Form.Control type="email" placeholder="name@company.com" disabled={isDisabled}
															value={email} onChange={e => setEmail(e.target.value)}
								/>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-3">
							<Form.Group id="phone">
								<Form.Label>Phone</Form.Label>
								<Form.Control type="text" placeholder="+12-345 678 910" disabled={isDisabled}
															value={phone} onChange={e => setPhone(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>

					<h5 className="my-4">Location</h5>
					<Row>
						<Col sm={9} className="mb-3">
							<Form.Group id="address">
								<Form.Label>Street</Form.Label>
								<Form.Control type="text" placeholder="Enter your home address" disabled={isDisabled}
															value={street} onChange={e => setStreet(e.target.value)} />
							</Form.Group>
						</Col>
						<Col sm={3} className="mb-3">
							<Form.Group id="addressNumber">
								<Form.Label>Number</Form.Label>
								<Form.Control type="number" placeholder="No."  disabled={isDisabled}
															value={streetNumber} onChange={e => setStreetNumber(e.target.value)}/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col sm={4} className="mb-3">
							<Form.Group id="city">
								<Form.Label>City</Form.Label>
								<Form.Control type="text" placeholder="City" disabled={isDisabled}
															value={city} onChange={e => setCity(e.target.value)}/>
							</Form.Group>
						</Col>
						<Col sm={4} className="mb-3">
							<Form.Group className="mb-2">
								<Form.Label>Select Country</Form.Label>
								<Form.Select id="state" value={country} onChange={e => setCountry(e.target.value)} disabled={isDisabled}>
									<option value="0">Country</option>
									<option value="ES">Spain</option>
									<option value="DR">Germany</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm={4}>
							<Form.Group id="zip">
								<Form.Label>ZIP</Form.Label>
								<Form.Control type="tel" placeholder="ZIP" disabled={isDisabled}
															value={zipCode} onChange={e => setZipCode(e.target.value)}/>
							</Form.Group>
						</Col>
					</Row>
					<div className="mt-3">
						<Button variant="gray-800" type="submit" className="mt-2 animate-up-2" disabled={isDisabled}>
							Save All
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};
