import React, {useState} from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";

export const CreateWalletForm = ({
	company,
	onSubmit,
}) => {
	const defaultValue = '';

	const [name, setName] = useState(company.name);
	const [provider, setProvider] = useState(company.email || defaultValue);
	const [currency, setCurrency] = useState(company.phone || defaultValue);

	//"name": "new wallet",
	//"companyId": "858275b0-e3c7-11ed-baad-6303556b1bf2",
	//"provider": "modular provider",
	//"currency": "usd"
	const handleSubmit = async (e) => {
		e.preventDefault();

		const updatedData = {
			name,
			provider,
			currency,
		};

		onSubmit(updatedData);
	};

	return (
		<Card border="0" className="shadow mb-4">
			<Card.Body>
				<h5 className="mb-4">New wallet info</h5>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6} className="mb-3">
							<Form.Group id="name">
								<Form.Label>Wallet name</Form.Label>
								<Form.Control
									required type="text" placeholder="Empty"
									value={name} onChange={e => setName(e.target.value)}
								/>
							</Form.Group>
						</Col>
					</Row>

					<div className="mt-3">
						<Button variant="gray-800" type="submit" className="mt-2 animate-up-2">
							Save All
						</Button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
};
