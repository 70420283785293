import axios from "axios";

import config from '../config';

let authToken = localStorage.getItem('token');

const apiClient = axios.create({
  baseURL: config.apiUrl,
})
apiClient.interceptors.request.use((axiosConfig) => {
  axiosConfig.headers['Access-Control-Allow-Origin'] = config.apiUrl
  axiosConfig.headers['x-api-key'] = authToken
  axiosConfig.headers['Content-Type'] = 'application/json'

  return axiosConfig;
})

export function setAuthToken(newToken) {
  localStorage.setItem('token', newToken);
  // axiosInstance.defaults.headers.common['x-api-key'] = newToken;

  return authToken = newToken;
}

function createRequestWithBody(requestType) {
  return async (params) => {
    const {path, data, headers} = params
    const res = await apiClient[requestType](path, data)

    if(res.data?.accessToken)
      setAuthToken(res.data.accessToken);

    return res
  }
}

export const request = {
  get: createRequestWithBody('get'),
  post: createRequestWithBody('post'),
  put: createRequestWithBody('put'),
  patch: createRequestWithBody('patch'),
  delete: createRequestWithBody('delete'),
}