import React, {useContext, useEffect, useState} from "react"
import {
	CashIcon,
	DocumentTextIcon,
	InformationCircleIcon,
	PlusIcon,
} from "@heroicons/react/solid"
import {useRouteMatch, useHistory} from "react-router-dom"
import {Badge, Button, ButtonGroup, Col, Dropdown, Row} from 'react-bootstrap'

import {Toasts} from 'components/Toast'
import {request} from 'services/http.service'
import {CompanyInfoForm} from "components/CompanyInfo.form"
import {UsersTable} from "components/tables/users-table"
import {convertDataToView} from '../services/date.service'
import {WalletsList} from "components/tables/wallets-list"
import CreateWalletModal from "components/CreateWalletModal"

import {Routes} from "../routes"
import {UserContext} from "../context/context.provider"
import {CompanyDocumentsModal} from "../components/UpdateDocuments.form"

export default ({match}) => {
	const { currentUser } = useContext(UserContext)

	const companySwitchValues = {
		info: 'info',
		wallets: 'wallets',
	}
	const companyId = match.params.id
	const { path } = useRouteMatch()
	const pagePath = path.split('/').pop()
	const isDisabled = !currentUser?.boUserPermissions?.companyEdit
	const canDeleteWallet = !!currentUser?.boUserPermissions?.walletDelete

	const history = useHistory()
	const [users, setUsers] = useState([])
	const [company, setCompany] = useState()
	const [wallets, setWallets] = useState([])
	const [showToast, setShowToast] = useState(false)
	const [toastSettings, setToastSettings] = useState({})
	const [companyDetails, setCompanyDetails] = useState(companySwitchValues[pagePath || companySwitchValues.info])
	const [showDocumentsModal, setShowDocumentsModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const openCreateWalletModal = () => setShowModal(true)
	const closeCreateWalletModal = () => setShowModal(false)
	// const notifications = 6

	useEffect(() => {
		Promise.all([
			requestCompanyUsers(companyId),
			requestCompany(companyId),
			requestCompanyWallets(companyId),
		])
			.then(([
				companyUsersRes,
				companyRes,
				companyWalletsRes,
			]) => {
				setUsers(companyUsersRes?.data?.map(prepareData))
				setCompany(companyRes?.data)
				setWallets(companyWalletsRes?.data)
		})
			.catch(err=> {
				console.log('err: ', err)
			})

	}, [])

	const openDocumentsModal = () => {
		setShowDocumentsModal(true)
	}
	const closeDocumentsModal = () => {
		setShowDocumentsModal(false)
	}

	const showDocumentsUpdateToast = () => {
		setToastSettings({
			message: 'Documents updated',
			background: 'success'
		})

		setShowToast(true)
	}

	const goToInfo = () => {
		setCompanyDetails(companySwitchValues.info)
		history.push(`${Routes.Company.path}/${companyId}`)
	}
	const goToWallets = () => {
		setCompanyDetails(companySwitchValues.wallets)
		history.push(`${Routes.Company.path}/${companyId}/wallets`)
	}

	const requestCompanyUsers = (companyId) => {
		return request.get({
			path: 'private/users/company/' + companyId,
		})
	}

	const requestCompany = (companyId) => {
		return request.get({
			path: 'private/companies/' + companyId,
		})
	}
	const requestCompanyWallets = (companyId) => {
		return request.get({
			path: 'private/wallets/company/' + companyId,
		})
	}

	const updateCompany = (companyData) => {
		requestUpdateCompanyData(company._id, companyData)
			.then(() => {
				setToastSettings({
					message: 'Company updated successfully',
					background: 'success'
				})

				setShowToast(true)
			})
	}

	const requestUpdateCompanyData = (companyId, company) => {
		return request.patch({
			path: 'private/companies/' + companyId,
			data: company
		})
	}

	const handleAddWallet = async (newWallet) => {
		const {data: wallet} = await createWallet(newWallet)
			closeCreateWalletModal()
			setWallets((companies) => [...companies, prepareData(wallet)])

			setToastSettings({
				message: 'Wallet created successfully',
				background: 'success'
			})

			setShowToast(true)
	}

	const createWallet = (data) => {
		return request.post({
			path: `private/wallets/create`,
			data
		})
	}

	const prepareData = (data) => ({
		...data,
		isSelected: false,
		show: true,
		dateCreated: convertDataToView(data.createdAt),
	})

	const handleCloseToast = () => setShowToast(false)

	const isNewButtonDisabled = () => {
		return !((companyDetails === companySwitchValues.info && currentUser?.boUserPermissions?.companyEdit)
			|| (companyDetails === companySwitchValues.wallets && currentUser?.boUserPermissions?.walletCreate))
	}

	return (
		<>
			<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
				<div>
					<Dropdown>
						<Dropdown.Toggle variant="secondary" className="d-inline-flex align-items-center me-2">
							{/*<PlusIcon className="icon icon-xs me-2" />*/}
							{/*{notifications > 0 && (*/}
							{/*	<Badge bg="danger">*/}
							{/*		{notifications}*/}
							{/*	</Badge>*/}
							{/*)}*/}
							{companyDetails === companySwitchValues.info
								? 'Account'
								: 'Add wallet'
							}
						</Dropdown.Toggle>
						<Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
							{
								companyDetails === companySwitchValues.info &&
								<Dropdown.Item
									className="d-flex align-items-center"
									onClick={openDocumentsModal}>
									<DocumentTextIcon className="dropdown-icon text-gray-400 me-2"/>
									Edit documents
								</Dropdown.Item>
							}
							{
								companyDetails === companySwitchValues.wallets &&
								<Dropdown.Item
									className="d-flex align-items-center"
									onClick={openCreateWalletModal}>
									<CashIcon className="dropdown-icon text-gray-400 me-2"/>
									Add wallet
								</Dropdown.Item>
							}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				<ButtonGroup>
					<Button variant="secondary" onClick={goToInfo}>
						<InformationCircleIcon className="icon icon-xs"/>
						{' Company info'}
					</Button>
					<Button variant="secondary" onClick={goToWallets}>
						<CashIcon className="icon icon-xs"/>
						{' Wallets'}
					</Button>
				</ButtonGroup>
			</div>
			{companyDetails === companySwitchValues.info && (
				<>
					<Row>
						<Col xs={12} xl={12}>
							{company && <CompanyInfoForm
								company={company}
								onSubmit={updateCompany}
								isDisabled={isDisabled}								
							/>}
						</Col>
					</Row>

					{
						showToast && <Toasts
							settings={toastSettings}
							handleCloseToast={handleCloseToast}
						/>
					}

					<UsersTable
						users={users.filter(u => u.show)}
						// allSelected={allSelected}
						// selectUser={selectUser}
						// deleteUsers={deleteUsers}
						// selectAllUsers={selectAllUsers}
					/>
				</>
			)}
			{companyDetails === companySwitchValues.wallets
				&& !!wallets.length
				&& (
				<WalletsList
					wallets={wallets}
					canDeleteWallet={canDeleteWallet}
				/>
			)}
			{ showDocumentsModal && <CompanyDocumentsModal
				company={company}
				showDocumentsUpdateToast={showDocumentsUpdateToast}
				closeModal={closeDocumentsModal}
			/>}
			{ showModal && <CreateWalletModal
				company={company}
				handleAddWallet={handleAddWallet}
				closeModal={closeCreateWalletModal}
			/>}
		</>
	)
}
