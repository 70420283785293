import React, {useEffect, useRef, useState} from "react";
import {Badge, Button, Modal, Table} from "react-bootstrap";
import {request} from "../services/http.service";

const companyDocumentsTitles = {
	articles: 'Articles/Memorandum of Association',
	registration: 'Certificate of Incorporation/Register',
	licence: 'License/Legal Opinion',
	addressProof: 'Proof of Address of the Company',
	bankStatement: 'Bank Statement',
	processHistory: 'Processing History of last 3 months',
	uboPassport: 'Passport (copy in color)',
	uboAddressProof: 'Proof of Address',
}

export const CompanyDocumentsModal = ({
	company,
	showDocumentsUpdateToast,
	closeModal
}) => {
	const companyDocumentsList = [
		'articles',
		'registration',
		'licence',
		'addressProof',
		'bankStatement',
		'processHistory',
	]
	const shareholderDocumentsList = [
		'uboPassport',
		'uboAddressProof',
	]

	const statusStyles = {
		approved: 'success',
		pending: 'secondary',
		required: 'warning',
		rejected: 'danger',
	}

	const downloadAvailableStatuses = [
		'approved',
		'pending',
		'rejected',
	]
	const uploadStylesStatuses = [
		'required',
		'rejected',
	]

	const requestCompanyDocuments = (companyId) =>
		request.get({
			path: 'private/documents/' + companyId
		})

	const formatCompanyDocuments = (docs) => companyDocumentsList.map(type => {
		const doc = docs[type]
		return {
			key: type,
			name: doc?.title || companyDocumentsTitles[type],
			status: doc?.status || 'required',
		}
	})

	const formatUboDocuments = (docs) => shareholderDocumentsList.map(type => {
		const doc = docs[type]
		return {
			key: type,
			name: doc?.title || companyDocumentsTitles[type],
			status: doc?.status || 'required',
		}
	})

	const handleDownload = (docType) => {
		const ref = documents[docType]?.location
		const fileNameParts = documents[docType]?.location.split('/')

		const link = document.createElement('a');
		link.href = ref;
		link.download = `${docType}_${fileNameParts[fileNameParts.length]}`;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const updateDocStatus = async (type, status) => {
		const data = {
			companyId: company._id,
			type,
			status
		}
		request.post({
			path: `private/documents`,
			data
		})
			.then(res => {
				setDocuments({
					...documents,
					[res.data.type]: res.data
				})
				showDocumentsUpdateToast()
			})
	}

	const [documents, setDocuments] = useState({})
	const companyDocuments = formatCompanyDocuments(documents)
	const uboDocuments = formatUboDocuments(documents)

	useEffect(() => {
		requestCompanyDocuments(company._id)
			.then(companyDocumentsRes => setDocuments(companyDocumentsRes?.data))
			.catch(err => {
				console.log('err: ', err)
			})
	}, [])

	return (
		<>
			<Modal className="create-company-dialog" centered size={'lg'} show={true}>
				<Modal.Header className="pb-0 border-0">
					<Modal.Title>Documents Management</Modal.Title>
					<Button
						variant="close"
						onClick={closeModal}
					/>
				</Modal.Header>
				<hr/>
				<Modal.Body>
					<h5>Company</h5>
					<Table striped bordered hover className="mb-3">
						<thead>
						<tr>
							<th>Document</th>
							<th>Status</th>
							<th>File</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{companyDocuments.map((doc) => (
							<tr key={doc.key}>
								<td>{doc.name}</td>
								<td style={{justifyContent: 'center'}}>
									<Button
										variant={`outline-${statusStyles[doc.status]}`}
										size="sm">
										{doc.status}
									</Button>
								</td>
								<td style={{display: 'flex', justifyContent: 'center'}}>
									<Button
										disabled={!downloadAvailableStatuses.includes(doc.status)}
										variant={!uploadStylesStatuses.includes(doc.status) ? 'secondary' : 'danger'}
										size="sm" onClick={(event) => handleDownload(doc.key)}>
										Download
									</Button>
								</td>
								<td>
									<Button
										disabled={!downloadAvailableStatuses.includes(doc.status)}
										variant={'outline-success'}
										size="sm" onClick={(event) => updateDocStatus(doc.key, 'approved')}>
										Approve
									</Button>
									<Button
										disabled={!downloadAvailableStatuses.includes(doc.status)}
										variant={'outline-warning'}
										size="sm" onClick={(event) => updateDocStatus(doc.key, 'rejected')}>
										Reject
									</Button>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
					<br/>
					<h5>UBO/ Shareholders/Directors</h5>
					<Table striped bordered hover className="mb-3">
						<thead>
						<tr>
							<th>Document</th>
							<th>Status</th>
							<th>File</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{uboDocuments.map((doc) => (
							<tr key={doc.key}>
								<td>{doc.name}</td>
								<td style={{justifyContent: 'center'}}>
									<Button
										variant={`outline-${statusStyles[doc.status]}`}
										size="sm">
										{doc.status}
									</Button>
								</td>
								<td style={{display: 'flex', justifyContent: 'center'}}>
									<Button
										disabled={!downloadAvailableStatuses.includes(doc.status)}
										variant={!uploadStylesStatuses.includes(doc.status) ? 'secondary' : 'danger'}
										size="sm" onClick={(event) => handleDownload(doc.key)}>
										Download
									</Button>
								</td>
								<td>
									<Button
										disabled={!downloadAvailableStatuses.includes(doc.status)}
										variant={'outline-success'}
										size="sm" onClick={(event) => updateDocStatus(doc.key, 'approved')}>
										Approve
									</Button>
									<Button
										disabled={!downloadAvailableStatuses.includes(doc.status)}
										variant={'outline-warning'}
										size="sm" onClick={(event) => updateDocStatus(doc.key, 'rejected')}>
										Reject
									</Button>
								</td>
							</tr>
						))}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={closeModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
