import React, {useContext} from "react";
import { ChatIcon, CloudUploadIcon, DocumentTextIcon, FireIcon, PlusIcon } from "@heroicons/react/solid";
import { Col, Dropdown, Row } from 'react-bootstrap';
import { request } from '../services/http.service';
import { UserInfoForm} from "../components/Forms";
import {UserContext} from "../context/context.provider";

export default () => {
  const { currentUser } = useContext(UserContext);

  const updateUser = (user) => {
    return request.patch({
      path: 'private/users/' + user.id,
      data: user
    });
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div>
          <Dropdown>
            <Dropdown.Toggle variant="secondary" className="d-inline-flex align-items-center me-2">
              <PlusIcon className="icon icon-xs me-2" /> New
            </Dropdown.Toggle>
            <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
              <Dropdown.Item className="d-flex align-items-center">
                <DocumentTextIcon className="dropdown-icon text-gray-400 me-2" /> Document
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <ChatIcon className="dropdown-icon text-gray-400 me-2" /> Message
              </Dropdown.Item>
              <Dropdown.Item className="d-flex align-items-center">
                <CloudUploadIcon className="dropdown-icon text-gray-400 me-2" /> Product
              </Dropdown.Item>

              <Dropdown.Divider as="div" className="my-1" />

              <Dropdown.Item className="d-flex align-items-center">
                <FireIcon className="dropdown-icon text-danger me-2" /> My Plan
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={10}>
          {currentUser && <UserInfoForm
            user={currentUser}
            onSubmit={updateUser}
          />}
        </Col>
      </Row>
    </>
  );
};
